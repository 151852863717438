<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a product") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <div
              class="me-3 "
              style="width: 100px"
            >
              <span>{{ $t("Image") }}</span>
              <file-upload
                v-model="item.image"
                add-class="mx-auto justify-center"
                :label="$t('Upload new photo')"
                folder="images/users/avatars/"
                :btn="true"
                :extra="false"
                :hide-label="false"
                icon-name="mdi-pencil"
                :icon="false"
                :show-download="false"
                inputname="file"
                :crop="true"
                :aspect-ratio="16/9"
                :max-crop-height="1400"
                :max-crop-width="700"
                accept="image/*"
              >
                <template #input>
                  <v-avatar
                    :color="item.image ? '' : 'primary'"
                    :class="item.image ? '' : 'v-avatar-light-bg primary--text'"
                    size="100"
                    rounded
                    class="mb-4"
                  >
                    <v-img
                      v-if="item.image"
                      :lazy-src="require(`@/assets/images/avatars/1.png`)"
                      :src="item.image || require(`@/assets/images/avatars/1.png`)"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-semibold text-5xl"
                    >{{ avatarText(item.name) }}</span>
                    <v-icon
                      size="20"
                      color="primary"
                      style="position: absolute;bottom: 10px; left: 10px"
                    >
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
              </file-upload>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.user_id"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
              :label="$t('User')"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="users"
              :loading="isLoadingusers"
              :search-input.sync="search"
              clearable
            >
              <template #item="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.name }" />
              </template>
              <template #selection="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.name }" />
              </template>
              <template #no-data>
                <span>{{ $t("User.") }} <strong>{{ search }}</strong> {{ $t("Doesn't exist.") }}</span>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="newuser"
                >
                  <span>{{ $t("Create user") }}</span>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-dialog
              v-model="isFormuserActive"
              touchless
              :right="!$vuetify.rtl"
              :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
            >
              <user-form
                v-model="item.user"
                :is-form-active="isFormuserActive"
                :with-addresses="false"
                @refetch-user="updateusers"
              >
                <template v-slot:form-title>
                  <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Update'): $t('Add new user') }}</span>
                  <v-spacer />
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    @click="isFormuserActive = !isFormuserActive"
                  >
                    <v-icon size="22">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
                <template #form-cancel>
                  <v-btn
                    color="secondary"
                    outlined
                    type="reset"
                    @click="isFormuserActive = !isFormuserActive"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </template>
              </user-form>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.address"
              outlined
              dense
              type="text"
              :error-messages="errorMessages.address"
              :label="$t('Address')"
              :placeholder="$t('Address')"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.weight"
              outlined
              dense
              type="number"
              :error-messages="errorMessages.weight"
              :label="$t('Weight')"
              :placeholder="$t('Weight')"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.height"
              outlined
              dense
              type="number"
              :error-messages="errorMessages.height"
              :label="$t('Height')"
              :placeholder="$t('Height')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <!-- <v-col
            cols="12"
          >
            <v-button
              v-model="item.tests"
              :label="$t('Tests')"
              :placeholder="$t('Tests')"
              outlined
              dense
              hide-details="auto"
              clearable
              @click.stop="openTestDialog(false)"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-button>
          </v-col> -->
          <v-col cols="12">
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              v-model="item.description"
              :label="$t('Decription')"
              :placeholder="$t('Description')"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="$emit('cancel')"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
  parse,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import usersController from '@/views/apps/user/user-list/useUsersList'
import AvatarName from '@/components/AvatarName'
import UserForm from '@/views/apps/user/user-list/UserForm'
import useUsersList from '@/views/apps/user/user-list/useUsersList'
import DatePicker from '@/components/DatePicker'
import FileUpload from '@/components/file_upload'

export default {
  components: {
    FileUpload,
    UserForm,
    AvatarName,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormuserActive = ref(false)
    const loading = ref(false)
    const users = ref([])
    const isLoadingusers = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    if (item.value.user && users.value.findIndex(ele => ele.id === item.value.user_id) < 0) {
      users.value.push(item.value.user)
    }
    watch(item, val => {
      if (item.value.user && users.value.findIndex(ele => ele.id === item.user_id) < 0) {
        users.value.push(item.value.user)
      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newuser = () => {
      item.value.user = {
        name: search.value, email: null, tell: null,
      }
      isFormuserActive.value = true
    }
    const searchusers = term => {
      store.dispatch('app/searchUsers', { term, rowsPerPage: 100 }).then(response => {
        isLoadingusers.value = false
        console.log('users', response.data.data)
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = users.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            users.value.push(u)
          }
        }
      })
    }
    const updateusers = user => {
      console.log(user)
      isFormuserActive.value = false
      users.value.push(user)
      item.value.user = user
      item.value.user_id = user.id
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-product/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    searchusers('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingusers.value) return ''
      isLoadingusers.value = true
      searchusers(val)

      return ''
    })

    const userIdChanged = computed({
      get: () => item.user_id,
    })

    watch(userIdChanged, val => {
      item.value.country_id = users.value.find(i => i.id === val).country_id

      return ''
    })

    // onMounted(searchParticular)

    return {
      minDate,
      resetItem,
      form,
      isFormuserActive,
      search,
      users,
      updateusers,
      newuser,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,

      item,
      valid,
      loading,
      validate,
      avatarText,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
