<template>

    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a Test") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-form
        ref="testsform"
        v-model="valid"
        @submit.prevent="onSubmit"
      >
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <v-card
              v-for="(test, index) in item.product_tests"
              :key="index"
              class="mt-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-autocomplete
                      v-model="item.product_tests[index].test_id"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.test_id"
                      :label="$t('Test')"
                      :item-text="`name.${$i18n.locale}`"
                      item-value="id"
                      outlined
                      dense
                      hide-details="auto"
                      :items="tests"
                      :loading="item.product_tests[index].loading"
                      @keyup="searchTests(item.product_tests[index])"
                      :search-input.sync="item.product_tests[index].search"
                      clearable
                    >
<!--                      <template #no-data>
                        <span>{{ $t("Test") }} <strong>{{ item.product_tests[index].search }}</strong> {{ $t("Doesn't exist.") }}</span>
                        <v-btn
                          depressed
                          small
                          color="primary"
                          @click.stop="newTest(item.product_tests[index].search)"
                        >
                          <span>{{ $t("Create Test") }}</span>
                        </v-btn>
                      </template>-->
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      color="danger"
                      @click.stop="removeTest(index, test)"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            color="info"
            class="me-3"
            @click="addTest"
          >
            {{ $t("Add test") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
      </v-form>
      <v-snackbar
        v-model="isSnackbarBottomVisible"
        :color="$vuetify.theme.currentTheme.error"
        dark
      >
        {{ snackBarMessage }}
      </v-snackbar>
      <v-dialog
        v-model="isFormtestActive"
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
      >
        <test-form
          v-model="newTestObject"
          :is-form-active="isFormtestActive"
          :with-addresses="false"
          @refetch-data="updatetests"
        >
          <template v-slot:form-title>
            <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Update'): $t('Add new test') }}</span>
            <v-spacer />
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isFormtestActive = !isFormtestActive"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <template #form-cancel>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click.stop="isFormtestActive = !isFormtestActive"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </template>
        </test-form>
      </v-dialog>
    </v-card>


</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'

import AvatarName from '@/components/AvatarName'
import TestForm from '@/views/apps/tests/TestForm'

import FileUpload from '@/components/file_upload'

export default {
  components: {
    FileUpload,
    TestForm,
    AvatarName,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      testsform,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormtestActive = ref(false)
    const loading = ref(false)
    const tests = ref([])
    const isLoadingTests = ref(false)
    const search = ref(null)
    const newTestObject = ref({name: {}, description: {}, results: {}})
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      testsform.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    watch(item, val => {
      /*if (item.value.user && tests.value.findIndex(ele => ele.id === item.user_id) < 0) {
        // tests.value.push(item.value.user)
      }*/
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeTest = (index, test) => {
      item.value.product_tests.splice(index, 1)
      /*if (test.id != null) {
        axios.post(`/products/action/delete_test/${item.value.id}`, { test_id: test.id })
      }*/
    }
    const addTest = () => {
      item.value.product_tests = item.value.product_tests || []
      item.value.product_tests.push({test_id: null, loading: false, search: null})
    }
    const formatDate = (date, f) => format(date, f)
    const newTest = (term) => {
      newTestObject.value = {
        name: { en: term,  fr: term },description: { en: term,  fr: term }, results: []
      }
      isFormtestActive.value = true
    }

    const findTests = (term, test) => {
      axios.get('/admin/tests', { params: { term: term, rowsPerPage: 100 } }).then(response => {
        if(test) test.loading = false


        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = tests.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            tests.value.push(u)
          }
        }
      })

    }
    const searchTests = test => {
      console.log('term',test.search)
      if(test.search.length > 2) {
        if(test.loading) return true
        test.loading = true
         findTests(test.search, test)
      }

    }
    const updatetests = test => {
      isFormtestActive.value = false
      tests.value.unshift(test)
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        axios.post(`/admin/products`, item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data
            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    findTests('', false)

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingTests.value) return ''
      isLoadingTests.value = true
      searchTests(val)

      return ''
    })

    const userIdChanged = computed({
      get: () => item.user_id,
    })

    watch(userIdChanged, val => {
      item.value.country_id = product_tests.value.find(i => i.id === val).country_id

      return ''
    })

    // onMounted(searchParticular)

    return {
      minDate,
      resetItem,
      testsform,
      isFormtestActive,
      newTestObject,
      searchTests,
      search,
      tests,
      updatetests,
      newTest,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,

      item,
      valid,
      loading,
      validate,
      avatarText,
      addTest,
      removeTest,
      isLoadingTests,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
